<script lang="ts" setup>
const route = useRoute();
const localePath = useLocalePath();
const layout = await useLayout();

const isOpenMobileMenu = ref(false);
const isOpenWebshopModal = ref(false);

function onCloseWebshopModal() {
  isOpenWebshopModal.value = false;
}

watch(() => route.path, () => isOpenMobileMenu.value = false); // close menu when path changes, eg. /nl/catalog -> /nl/contact
</script>

<template>
  <header v-if="layout">
    <DatoCmsPreviewBanner />
    <div class="container mx-auto">
      <div class="my-md md:my-lg flex items-center justify-between">
        <NuxtLinkLocale to="/">
          <NuxtImg src="/logo/spuntini-logo-text.svg" alt="Spuntini Logo" title="Spuntini" class="w-32 lg:w-48" />
        </NuxtLinkLocale>

        <div class="hidden items-center lg:flex">
          <nav class="mx-3xl gap-lg flex">
            <NuxtLinkLocale
              v-for="item in layout.topNavigation"
              :key="item.id"
              :to="`/${item.slug}`"
              :class="{ 'text-primary !border-primary': route.path?.toString().includes(item.slug) }"
              class="text-md hover:border-th-neutral-500 border-b-2 border-transparent leading-6 transition"
              active-class="text-primary !border-primary"
            >
              {{ item.title }}
            </NuxtLinkLocale>
          </nav>

          <div class="gap-md flex">
            <UButton variant="outline" color="primary" size="sm" @click="isOpenWebshopModal = true">
              {{ layout.webshopButtonText }}
            </UButton>

            <HeaderLocaleSwitcher />
          </div>
        </div>

        <div class="gap-lg flex items-center lg:hidden">
          <div class="relative">
            <VectorWithStroke class="vector--main-nav-button absolute -left-0.5 -top-0.5 h-[0.9rem] w-4 rotate-[55deg]" />
            <UButton variant="solid" color="primary" size="xs" :to="localePath(`/${layout.headerButtonLink.slug}`)">
              {{ layout.headerButtonText }}
            </UButton>
          </div>

          <UIcon name="i-ph-list" class="size-10 cursor-pointer" @click="isOpenMobileMenu = true" />
        </div>
      </div>

      <div class="hidden rounded-full bg-white lg:block">
        <div class="flex items-center justify-between">
          <nav class="mx-xl gap-xl flex flex-wrap">
            <NuxtLinkLocale
              v-for="item in layout.mainNavigation"
              :key="item.id"
              :to="`/${item.slug}`"
              :class="{ 'text-primary !border-primary': route.path?.toString().includes(item.slug) }"
              class="text-md hover:border-th-neutral-500 border-b-2 border-transparent py-[22px] font-semibold transition"
              active-class="text-primary !border-primary"
            >
              {{ item.title }}
            </NuxtLinkLocale>
          </nav>

          <div class="relative">
            <VectorWithStroke class="vector--main-nav-button absolute left-2 top-2 h-[0.9rem] w-4 rotate-[55deg]" />
            <UButton variant="solid" color="primary" size="md" :to="localePath(`/${layout.headerButtonLink.slug}`)" class="m-xs">
              {{ layout.headerButtonText }}
            </UButton>
          </div>
        </div>
      </div>
    </div>

    <USlideover v-model="isOpenMobileMenu" prevent-close>
      <div class="flex flex-1 overflow-y-auto bg-[#F8F7F4]">
        <div class="px-md size-full">
          <div class="my-md flex items-center justify-between">
            <NuxtLinkLocale to="/" class="focus-visible:outline-none">
              <NuxtImg src="/logo/spuntini-logo-text.svg" alt="Spuntini Logo" title="Spuntini" class="w-32 lg:w-48" />
            </NuxtLinkLocale>

            <UIcon name="i-ph-x" class="size-10 cursor-pointer" @click="isOpenMobileMenu = false" />
          </div>

          <div class="pb-3xl">
            <nav class="my-xl gap-lg flex flex-col">
              <NuxtLinkLocale
                v-for="item in layout.mainNavigation"
                :key="item.id"
                :to="`/${item.slug}`"
                :class="{ 'text-primary !border-primary': route.path?.toString().includes(item.slug) }"
                class="text-md hover:border-th-neutral-500 border-b-2 border-transparent leading-6"
                active-class="text-primary !border-primary"
                @click="isOpenMobileMenu = false"
              >
                {{ item.title }}
              </NuxtLinkLocale>

              <NuxtLinkLocale
                v-for="item in layout.topNavigation"
                :key="item.id"
                :to="`/${item.slug}`"
                :class="{ 'text-primary !border-primary': route.path?.toString().includes(item.slug) }"
                class="text-md hover:border-th-neutral-500 border-b-2 border-transparent leading-6"
                active-class="text-primary !border-primary"
                @click="isOpenMobileMenu = false"
              >
                {{ item.title }}
              </NuxtLinkLocale>
            </nav>

            <div class="my-xl gap-md flex flex-col">
              <UButton
                variant="solid" color="primary" size="sm"
                :to="localePath(`/${layout.headerButtonLink.slug}`)"
                @click="isOpenMobileMenu = false"
              >
                {{ layout.headerButtonText }}
              </UButton>

              <hr class="my-md border-th-neutral-100">

              <HeaderWebshopLinks />
            </div>

            <div class="my-xl">
              <HeaderLocaleSwitcher />
            </div>
          </div>
        </div>
      </div>
    </USlideover>

    <LazyHeaderWebshopModal :is-open="isOpenWebshopModal" @close="onCloseWebshopModal" />
  </header>
</template>

<style>
.vector--main-nav-button {
  --esc-vector-fill-color: #c81414;
  --esc-vector-opacity: 1;
  --esc-vector-mix-blend-mode: normal;
  --esc-vector-stroke-color: #fff;
  --esc-vector-stroke-width: 8;
}
</style>
