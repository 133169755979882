<script lang="ts" setup>
const { t } = useI18n();

useHead({
  title: () => t("layouts.title"),
  titleTemplate: "%s",
});

// i18n
const head = useLocaleHead({
  dir: true,
  lang: true,
  seo: true,
  key: "id",
});
</script>

<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <Head>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>

      <!-- https://favicon.inbrowser.app/tools/favicon-generator -->
      <Link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <Link rel="icon" href="/favicon.ico" sizes="48x48" />
      <Link rel="icon" href="/favicon.svg" sizes="any" type="image/svg+xml" />
      <Link rel="manifest" href="/site.webmanifest" />
      <Meta name="theme-color" content="#C81414" media="(prefers-color-scheme: light)" />
      <Meta name="theme-color" content="#C81414" media="(prefers-color-scheme: dark)" />
      <Meta name="google-site-verification" content="Ku8sCA5QouiTklVyhWN0ITVZZA0v-kzSFJk4oEW0-mw" />
    </Head>
    <Body class="bg-[#F8F7F4]">
      <HeaderBase />
      <main>
        <div class="container mx-auto">
          <slot />
        </div>
      </main>
      <FooterBase />
    </Body>
  </Html>
</template>

<style></style>
