<script lang="ts" setup>
const layout = await useLayout();
</script>

<template>
  <footer v-if="layout" class="mt-6 bg-[#f3f1ec]">
    <div v-if="layout?.usps">
      <div class="container mx-auto py-10">
        <FooterUsps :data="layout.usps" />
      </div>
    </div>

    <div class="bg-th-neutral-500 rounded-t-lg text-white">
      <div class="container mx-auto py-10">
        <FooterMain :data="layout" />
      </div>
    </div>

    <div class="bg-[#503E3E] text-sm text-white">
      <div class="container mx-auto py-3">
        <FooterClosing :data="layout" />
      </div>
    </div>

    
  </footer>
</template>

<style scoped>
</style>
