<script lang="ts" setup>
const { locale, t } = useI18n();

async function goToLink(linkUrl: string) {
  await navigateTo(linkUrl, { external: true, open: { target: "_blank" } });
}
</script>

<template>
  <div class="gap-lg flex flex-col">
    <UButton @click="goToLink(`https://deerlijk.spuntini.be/${locale}/`)">
      {{ t("webshop.label") }}
    </UButton>
    <UButton @click="goToLink(`https://roeselare.spuntini.be/${locale}/`)">
      {{ t("webshop.label.roeselare") }}
    </UButton>
    <UButton @click="goToLink('https://webshop.isales.be/#/users/login')">
      {{ t("webshop.label.i-sales") }}
    </UButton>
  </div>
</template>

<style scoped>

</style>
