<script lang="ts" setup>
import type { LocaleObject } from "@nuxtjs/i18n";

const { locale: currentLocale, locales } = useI18n();
const switchLocalePath = useSwitchLocalePath();

const items = computed(() => [locales.value
  .map((locale: LocaleObject) => {
    return {
      label: locale.code!.substring(0, 2).toUpperCase(),
      to: `${switchLocalePath(locale.code)}`,
    };
  })]);
</script>

<template>
  <div>
    <UDropdown
      :items="items"
      :ui="{ background: 'bg-[#F3F1EC]', width: 'w-16', item: { base: 'justify-center text-primary font-medium' } }"
      :popper="{ placement: 'bottom-start' }" class="hidden lg:block"
    >
      <UButton
        variant="solid" color="primary" size="xs" trailing-icon="i-ph-caret-down"
        :ui="{ strategy: 'override', variant: { solid: `bg-[#EBE9E9] text-primary` } }"
      >
        {{ currentLocale.substring(0, 2).toUpperCase() }}
      </UButton>
    </UDropdown>

    <div class="gap-md flex lg:hidden">
      <NuxtLink
        v-for="option in (locales as LocaleObject[])"
        :key="option.code"
        :to="`/${option.code}`"
        :class="{
          'bg-[#F3F1EC]': option.code !== currentLocale,
          'bg-[#EBE9E9]': option.code === currentLocale,
        }"
        class="text-primary flex size-10 items-center justify-center rounded-full text-sm font-medium"
        @click.prevent.stop="switchLocalePath(option.code)"
      >
        {{ option.code!.substring(0, 2).toUpperCase() }}
      </NuxtLink>
    </div>
  </div>
</template>

<style>

</style>
